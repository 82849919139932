import React from "react"

import SEO from "../components/Base/SEO"
import Form from "../components/Enquiry/Form"
import Layout from "../components/Base/Layout"

export default function (props) {
  return (
    <Layout>
      <SEO title={"Sheer Analytics and Insights Ask For Discount"} />
      <Form
        headingText={"analyst"}
        linkText={"AskForDiscount"}
        slug={props['*']}
      />
    </Layout>
  )
}
